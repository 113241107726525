import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { PageProps } from 'gatsby'
import { Text } from '@/app/atoms/text/text'
import Img from 'gatsby-image'
import { AboutPageQuery_Manual } from '@/../manual-graphql-types'
import { SEO } from '@/app/organisms/layout/components/seo'

type AboutProps = {
  data: AboutPageQuery_Manual
}
// TODO use gatsby image where you can
const About: React.FC<AboutProps> = ({ data }) => {
  const { allSanityAbout } = data
  const { node } = allSanityAbout.edges[0]

  return (
    <>
      <SEO pageTitle="About Us" />
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          max-height: 520px;
        `}
      >
        <Img fluid={node.heroImage.asset.fluid} />
        <section
          css={css`
            width: 100%;
            margin: 0px 16px;
          `}
        >
          <Text type="heroTitle">About</Text>
          <Text type="body">We love to do it</Text>
        </section>
      </div>
    </>
  )
}

export const query = graphql`
  query AboutPage {
    allSanityAbout {
      edges {
        node {
          description
          heroImage {
            asset {
              fluid(maxWidth: 2100) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
export default About
